import { FirebaseContent, FirebasePost } from './FirebasePost';
import { Locale } from 'Locale';
import { Content, Post } from 'Post';

export const mapContent = (content: Content): FirebaseContent =>
	({...content, publishTime: content.publishTime?.getTime() ?? 0});

export const mapData = (post: Post): FirebasePost => ({
	categories: post.categories,
	categorySlugs: post.categorySlugs,
	content: {
		[Locale.CS]: mapContent(post.content.cs),
		[Locale.EN]: mapContent(post.content.en),
	},
	createdAt: post.createdAt.getTime(),
	files: post.files,
	id: post.id,
	openGraph: post.openGraph,
	orderIndex: post.orderIndex,
	people: post.people,
	peopleIds: post.peopleIds,
	poster: post.poster,
	rows: post.rows,
	style: post.style,
	tags: post.tags,
	related: post.related,
});
