import Firebase from 'firebase/app';
import { FirebaseContent } from './FirebasePost';
import { Category } from 'Category';
import { addDocument, updateDocument } from 'Firebase';
import { Locale } from 'Locale';
import { Person, Post, Poster, PostFile, PostOpenGraph, PostRow, PostStyle, Tag } from 'Post';

export interface ContentValues {
	awards: {html: string; markup: string};
	client: {html: string; markup: string};
	cooperation: {html: string; markup: string};
	description: {html: string; markup: string};
	location: {html: string; markup: string};
	published: boolean;
	publishTime: string;
	slug: string;
	title: string;
	typefaces: {html: string; markup: string};
	year: string;
}

export interface Values {
	categories: Category[];
	content: { [locale in Locale]: ContentValues };
	files: PostFile[];
	openGraph: PostOpenGraph;
	people: Person[];
	poster: Poster | null;
	rows: PostRow[];
	style: PostStyle;
	tags: Tag[];
	related: Related[];
}

const calculateNewOrderIndex = async (): Promise<number> => {
	const query = Firebase.firestore().collection('posts').orderBy('orderIndex', 'desc').limit(1);
	const docs = (await query.get()).docs;
	if (docs.length === 0) {
		return 0;
	}
	const index = (docs[0].data() as {orderIndex: number}).orderIndex + 100;
	return Math.round(index / 100) * 100;
};

const mapContentValues = async (postId: string | null, locale: Locale, content: ContentValues): Promise<FirebaseContent> => {
	const publishTime = content.publishTime;
	let slug = content.slug;
	let hasConflict = false;
	let count = 0;

	do {
		if (count > 0) {
			slug = `${content.slug}-${count}`;
		}
		const query = Firebase.firestore().collection('posts').where(`content.${locale}.slug`, '==', slug).limit(1);
		const docs = (await query.get()).docs.filter((doc) => postId === null || doc.id !== postId);
		hasConflict = docs.length > 0;
		count += 1;

	} while (hasConflict);

	return {...content, publishTime: publishTime !== '' ? (new Date(publishTime)).getTime() : 0, slug};
};

export const savePost = async (post: Post | null, values: Values) => {
	const contents = await Promise.all([
		mapContentValues(post?.id ?? null, Locale.CS, values.content.cs),
		mapContentValues(post?.id ?? null, Locale.EN, values.content.en),
	]);

	const document = {
		categories: values.categories,
		categorySlugs: {
			[Locale.CS]: values.categories.map((category) => category.content.cs.slug),
			[Locale.EN]: values.categories.map((category) => category.content.en.slug),
		},
		content: {
			[Locale.CS]: contents[0],
			[Locale.EN]: contents[1],
		},
		createdAt: (post !== null ? post.createdAt : (new Date())).getTime(),
		files: values.files,
		openGraph: values.openGraph,
		orderIndex: post?.orderIndex ?? await calculateNewOrderIndex(),
		people: values.people,
		peopleIds: values.people.map((person) => person.id),
		poster: values.poster,
		rows: values.rows,
		style: values.style,
		tags: values.tags,
		related: values.related,
	};

	if (post !== null) {
		await updateDocument('posts', post.id, document);
	} else {
		await addDocument('posts', document);
	}
};
