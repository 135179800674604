import { FirebaseContent, FirebasePost } from '/FirebasePost/FirebasePost';
import { Locale } from 'Locale';
import { Content, Post } from 'Post';

export const mapContent = (content: FirebaseContent): Content =>
	({...content, publishTime: content.publishTime !== 0 ? new Date(content.publishTime) : null});

export const mapPost = (post: FirebasePost): Post => ({
	categories: post.categories,
	categorySlugs: post.categorySlugs,
	content: {
		[Locale.CS]: mapContent(post.content.cs),
		[Locale.EN]: mapContent(post.content.en),
	},
	createdAt: new Date(post.createdAt),
	files: post.files,
	id: post.id,
	openGraph: post.openGraph,
	orderIndex: post.orderIndex,
	people: post.people,
	peopleIds: post.peopleIds,
	poster: post.poster,
	rows: post.rows,
	style: post.style,
	tags: post.tags,
	related: post.related,
});
