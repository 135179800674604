import * as React from "react";
import { useState } from "react";
import { Link } from "wouter";
import { fetchByIds } from "Firebase";
import { buildImageSourceSet, useFollowingPost } from "FirebasePost";
import { Locale, localize, selectCardinalPlural } from "Locale";
import { Person } from "Person";
import { Post, PostImage, PostPart, PostPartType, PostRow } from "Post";
import "./styles.less"; // tslint:disable-line: no-import-side-effect
import { calculateStyle, LayoutType, PostLayout } from "PostLayout";
import { linkPost } from "Router";

import { default as _ReactPlayer } from "react-player/vimeo";
import { ReactPlayerProps } from "react-player/types/lib";
const ReactPlayer = _ReactPlayer as unknown as React.FC<ReactPlayerProps>;
// import ReactPlayer from "react-player/vimeo";

const ImageDetail: React.FunctionComponent<{ images: PostImage[] }> = ({
  images,
}) => {
  const [loaded, setLoaded] = React.useState(false);

  return (
    <img
      srcSet={buildImageSourceSet(images)}
      className={`fadeout ${loaded ? "fadeout-visible" : ""}`}
      loading="lazy"
      onLoad={() => {
        setLoaded(true);
      }}
    />
  );
};

const PartDetail: React.FunctionComponent<{
  autoplay: boolean;
  part: PostPart;
  playInLoop: boolean;
}> = ({ autoplay, part, playInLoop }) => {
  switch (part.type) {
    case PostPartType.Image:
      return <ImageDetail images={part.images} />;

    /* TODO INLINE VIDEO CASE*/

    case PostPartType.Video:
      const params = new URLSearchParams();
      // const [playing, setPlaying] = useState(true);

      let playerParams = [];
      let playing = false;
      let looping = false;
      let showcontrols = false;
      if (autoplay) {
        params.append("autoplay", "1");
        params.append("muted", "1");
        params.append("playsinline", "1");
        playerParams.push("playing", true);
        playing = true;
      }
      if (playInLoop) {
        params.append("loop", "1");
        looping = true;
      }

      return (
        /*<iframe
          src={`${part.video.url}${
            part.video.url.indexOf("?") >= 0 ? "&" : "?"
          }${params.toString()}`}
        />*/
        <div
          className="videoToggle videoToggle-play"
          // onClick={() => setPlaying(!playing)}
        >
          <ReactPlayer
            url={part.video.url}
            width="100%"
            height="100%"
            controls={showcontrols}
            muted={true}
            playing={playing}
            playsinline={true}
            loop={looping}
          />
        </div>
      );

    default:
      return null;
  }
};

const RowDetail: React.FunctionComponent<{
  borderColor: string | null;
  index: number;
  row: PostRow;
}> = ({ borderColor, index, row }) => (
  <div
    className={`row ${
      borderColor !== null && row.showBorders ? "" : "row-nobr"
    } ${index === 0 && row.showBorders ? "row-first" : ""}`}
  >
    <PostLayout type={row.layout.type}>
      {(index, className) => (
        <div
          className={`${className} ${
            row.parts[index].type === PostPartType.Empty ? "cell-empty" : ""
          }`}
          style={calculateStyle(row.layout.type, row.parts[index])}
        >
          <PartDetail
            part={row.parts[index]}
            autoplay={row.autoplay}
            playInLoop={row.playInLoop}
          />
          {borderColor !== null && row.showBorders ? (
            <i className="br" style={{ borderColor }} />
          ) : null}
        </div>
      )}
    </PostLayout>
  </div>
);

export const PostDetail: React.FunctionComponent<{
  category?: string;
  locale: Locale;
  post: Post;
  onClose(): void;
}> = ({ category = null, post, locale, onClose }) => {
  const prevPost = useFollowingPost(post.orderIndex, category, locale, "prev");
  const nextPost = useFollowingPost(post.orderIndex, category, locale, "next");

  const [people, setPeople] = React.useState<Person[] | null>(null);
  React.useEffect(() => {
    (async () => {
      setPeople(await fetchByIds("people", post.peopleIds));
    })();
  }, [post]);

  const [showInfo, setShowInfo] = React.useState(false);
  const scrolled = () => {
    requestAnimationFrame(() => {
      setShowInfo(window.scrollY >= 25);
    });
  };

  React.useEffect(() => {
    window.addEventListener("scroll", scrolled);
    return () => {
      window.removeEventListener("scroll", scrolled);
    };
  }, []);

  const footerRef = React.useRef<HTMLDivElement>(null);
  const offsetContent = React.useMemo(() => {
    if (post.rows.length === 0) {
      return false;
    }
    const lastRow = post.rows[post.rows.length - 1];
    return lastRow.layout.type === LayoutType.Full && !lastRow.showBorders;
  }, [post]);

  return (
    <section
      className={`section-detail ${
        post.style.borders !== null ? "show-border" : ""
      }`}
      style={post.style}
    >
      <div
        className={
          offsetContent ? "detail-content-offsetted" : "detail-content"
        }
      >
        <h1>
          <button
            onClick={() => {
              footerRef.current?.scrollIntoView({ behavior: "smooth" });
            }}
            style={{ color: post.style.color ?? undefined }}
          >
            {showInfo ? "info" : post.content[locale].title}
          </button>
        </h1>
        <div onClick={onClose}>
          {post.rows.map((row, index) => (
            <RowDetail
              key={row.id}
              index={index}
              borderColor={
                post.style.borders !== null ? post.style.backgroundColor : null
              }
              row={row}
            />
          ))}
        </div>
      </div>

      {
        // REMOVE PREV NEXT BUTTONS ON DESKTOP
        /*nextPost !== null ? <Link to={linkPost(locale, nextPost.content[locale].slug, category)}>
			<a className='detail-btn-next'><p>{nextPost.content[locale].title}</p></a>
		</Link> : null}
		{prevPost !== null ? <Link to={linkPost(locale, prevPost.content[locale].slug, category)}>
			<a className='detail-btn-prev'><p>{prevPost.content[locale].title}</p></a>
		</Link> : null*/
        // REMOVE PREV NEXT BUTTONS ON DESKTOP
      }

      <div className="detail-footer" ref={footerRef}>
        <div className="detail-footer-top">
          <h1>{post.content[locale].title}</h1>
          <p className="p-tagslist">
            {post.tags.map((tag) => (
              <span key={tag.id}>{tag.title[locale]}</span>
            ))}
          </p>
          <p
            dangerouslySetInnerHTML={{
              __html: post.content[locale].location.html,
            }}
          />
          <p>{post.content[locale].year}</p>
        </div>
        <div className="detail-footer-content">
          {post.content[locale].description.html !== "" ? (
            <div className="detail-footer-content-left">
              <p
                dangerouslySetInnerHTML={{
                  __html: post.content[locale].description.html,
                }}
              />
            </div>
          ) : null}

          <div className="detail-footer-content-right">
            {post.people.length > 0 ? (
              <>
                <h3>
                  {localize(
                    locale,
                    selectCardinalPlural(post.people.length, [
                      "Designer",
                      "Designers",
                    ])
                  )}
                </h3>
                <p>
                  {people?.map((person, index) => (
                    <React.Fragment key={person.id}>
                      {person.internal ? (
                        `${person.firstName}\xa0${person.lastName}`
                      ) : (
                        <Link
                          to={`/${locale}/info/${encodeURIComponent(
                            person.id
                          )}`}
                        >{`${person.firstName}\xa0${person.lastName}`}</Link>
                      )}
                      {index < post.people.length - 1 ? ", " : ""}
                    </React.Fragment>
                  ))}
                </p>
              </>
            ) : null}

            {post.content[locale].awards.html !== "" ? (
              <>
                <h3>{localize(locale, "Awards")}</h3>
                <p>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: post.content[locale].awards.html,
                    }}
                  />
                </p>
              </>
            ) : null}
            {post.content[locale].client.html !== "" ? (
              <>
                <h3>{localize(locale, "Client")}</h3>
                <p>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: post.content[locale].client.html,
                    }}
                  />
                </p>
              </>
            ) : null}
            {post.content[locale].cooperation.html !== "" ? (
              <>
                <h3>{localize(locale, "Cooperation")}</h3>
                <p>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: post.content[locale].cooperation.html,
                    }}
                  />
                </p>
              </>
            ) : null}
            {post.content[locale].typefaces.html !== "" ? (
              <>
                <h3>{localize(locale, "Typefaces")}</h3>
                <p>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: post.content[locale].typefaces.html,
                    }}
                  />
                </p>
              </>
            ) : null}
          </div>
        </div>
        <div className="detail-footer-btns">
          {prevPost !== null ? (
            <Link
              to={linkPost(locale, prevPost.content[locale].slug, category)}
            >
              <a className="btn-mobile-prev">prev</a>
            </Link>
          ) : null}

          <button onClick={onClose}>×</button>

          {nextPost !== null ? (
            <Link
              to={linkPost(locale, nextPost.content[locale].slug, category)}
            >
              <a className="btn-mobile-next">next</a>
            </Link>
          ) : null}
        </div>
      </div>
      {/*
      TODO GRID OF RELATED PROJECTS - CONDITIONAL DISPLAY = IF THERE ARE ANY IN RELATED META
      <div className="related-projects"></div>
      */}
    </section>
  );
};
