import * as React from 'react';
import { Header } from 'Application/Header';
import Firebase from 'firebase/app';
import { useCollection } from 'Firebase';
import { FirebasePost } from 'FirebasePost';

import React, {useState, useEffect} from 'react';



export const SelectRelPost = <
	Item extends {id: string},
	SelectedItem extends {id: string},
>({collection, selected, onChange}: {
	// items: Item[];
	collection: string;
	selected: SelectedItem[];
	onChange(selected: Item[]): void;
	// children(item: Item): React.ReactElement;
}): React.ReactElement => {

	const [posts, setPosts] = useState([]);

	useEffect( () => {
		const RelatedSelect2 = async () => {
			const query = await Firebase.firestore().collection('posts').orderBy('createdAt').get();
			// const query = await Firebase.firestore().collection('posts').where(`content.${locale}.published`, '==', true);
			const docs = query.docs.map((doc) => ({id: doc.id, ...doc.data()})) as FirebasePost[];
			
			await Promise.all(docs.map(consPostDet));
		};
		const consPostDet = async (post: FirebasePost): Promise<void> => {
			// console.log('Post', post.id);
			// if(post.content?.published) {
				setPosts(posts => [...posts, post] );
			// }
		};
		RelatedSelect2();
	}, []);
	
	const items = useCollection<Item>(collection);
	const selectedSet = React.useMemo(() => new Set(selected.map((item) => item.id)), [selected]);

	return <>
		{posts.map((post) => <label className='item-radio' key={post.id}>
			<input
				type='checkbox'
				checked={selectedSet.has(post.id)}
				onChange={() => {
					onChange(posts.filter((otherItem) => {
						const isSelected = selectedSet.has(otherItem.id);
						return post.id === otherItem.id ? !isSelected : isSelected;
					}));
				}}
			/>
			{' '}{post.content['cs'].title}
		</label>)}
	</>;
};
SelectRelPost.displayName = 'SelectRelPost';


export const FirebaseSelectRelPost = <
	Item extends {id: string},
	SelectedItem extends {id: string},
>({collection, selected, onChange}: {
	collection: string;
	selected: SelectedItem[];
	children(item: Item): React.ReactElement;
	onChange(selected: Item[]): void;
}): React.ReactElement | null => {
	const items = useCollection<Item>(collection);
	return items !== null
		? <SelectRelPost items={items} selected={selected} onChange={onChange} />
		: null;
};

FirebaseSelectRelPost.displayName = 'FirebaseSelectRelPost';


export const RelatedPosts: React.FunctionComponent<{
	title: string,
	/*
	selected: SelectedItem[];
	selectedItem: string;
	children(item: Item): React.ReactElement;
	onChange(selected: Item[]): void;
	*/
}> = ({title, children, onChange}) => {
// }> = ({title, item, selectedItem, children, onChange}) => {
	const [posts, setPosts] = useState([]);


	useEffect( () => {
		const RelatedSelect2 = async () => {
			const query = await Firebase.firestore().collection('posts').orderBy('createdAt').get();
			const docs = query.docs.map((doc) => ({id: doc.id, ...doc.data()})) as FirebasePost[];
			// posts = query.docs.map((doc) => ({id: doc.id, ...doc.data()})) as FirebasePost[];
			
			await Promise.all(docs.map(consPostDet));
			// console.log('Finished');
			// await Promise.all(docs.map((doc) => <h1>id={doc.id} title={doc.content['cs'].title}</h1> ));
		};
		const consPostDet = async (post: FirebasePost): Promise<void> => {
			console.log('Post', post.id);
			// setPosts(posts => [...posts, post.id] );
			setPosts(posts => [...posts, post] );
		};
		RelatedSelect2();
	}, []);
	
	return <>
		
		{posts.map((post) => <label className='item-radio' key={post.id}>
			<input
				type='checkbox'
				// checked={selectedSet.has(post.id)}
				/*onChange={() => {
					onChange(items.filter((otherPost) => {
						const isSelected = selectedSet.has(otherPost.id);
						return post.id === otherPost.id ? !isSelected : isSelected;
					}));
				}}*/
			/>
			{' '}{post.content['cs'].title}
		</label>)}
	</>;
};