import * as React from 'react';
import { useCollection } from 'Firebase';

export const MultiSelect = <
	Item extends {id: string},
	SelectedItem extends {id: string},
>({items, selected, children, onChange}: {
	items: Item[];
	selected: SelectedItem[];
	children(item: Item): React.ReactElement;
	onChange(selected: Item[]): void;
}): React.ReactElement => {
	const selectedSet = React.useMemo(() => new Set(selected.map((item) => item.id)), [selected]);
	return <>
		{items.map((item) => <label className='item-radio' key={item.id}>
			<input
				type='checkbox'
				checked={selectedSet.has(item.id)}
				onChange={() => {
					onChange(items.filter((otherItem) => {
						const isSelected = selectedSet.has(otherItem.id);
						return item.id === otherItem.id ? !isSelected : isSelected;
					}));
				}}
			/>
			{' '}{children(item)}{' '}
		</label>)}
	</>;
};

MultiSelect.displayName = 'MultiSelect';

export const FirebaseMultiSelect = <
	Item extends {id: string},
	SelectedItem extends {id: string},
>({collection, selected, children, onChange}: {
	collection: string;
	selected: SelectedItem[];
	children(item: Item): React.ReactElement;
	onChange(selected: Item[]): void;
}): React.ReactElement | null => {
	const items = useCollection<Item>(collection);
	return items !== null
		? <MultiSelect items={items} selected={selected} children={children} onChange={onChange} />
		: null;
};

FirebaseMultiSelect.displayName = 'FirebaseMultiSelect';
